import { RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from "../../../regs";

class EEFRSelector extends RegistryDescriptor {
    constructor(name: ApplicationUnit) {
        super();
        this.name = name;
        this.selector = {
            endpoint: name as string,
            idCol: 'code',
            idColType: 'string',
            captionCol: 'code',
            textCol: 'name',
            queryParams: { _orderBy: 'code' }
        };
    }
}

export const eefr_economic_content_selector = new EEFRSelector('eefr_economic_content_selector');
export const eefr_data_presentment_selector = new EEFRSelector('eefr_data_presentment_selector');
export const eefr_asset_group_selector = new EEFRSelector('eefr_asset_group_selector');
export const eefr_related_party_selector = new EEFRSelector('eefr_related_party_selector');
export const eefr_change_type_selector = new EEFRSelector('eefr_change_type_selector');