import { SimpleCodedNamedWithMemoRD } from '../../app/types/SimpleCodedNamedWithMemoRD';
import { accounts, detail_accounts_selector } from './registries/accounts/accounts';
import { eefr_asset_group_selector, eefr_data_presentment_selector, eefr_economic_content_selector,
    eefr_related_party_selector, eefr_change_type_selector } from './registries/eefr_classifiers/eefr_classifiers';
import { general_ledger } from './registries/general_ledger/general_ledger';
import { balance_report } from './reports/balance_report/balance_report';
import { profit_report } from './reports/profit_report/profit_report';
import { accounts_report } from './reports/accounts_report/accounts_report';
import { ereports } from './registries/ereports/ereports';
import { income_expense_report } from './reports/income_expense_report/income_expense_report';
import { unpaid_bills_report } from './reports/unpaid_bills_report/unpaid_bills_report';

const ledger_types = new SimpleCodedNamedWithMemoRD('ledger_types'); // TODO temporary simplified registry

export const accounts_regs = { accounts, detail_accounts_selector, general_ledger, ledger_types, 
    eefr_economic_content_selector, eefr_data_presentment_selector, eefr_asset_group_selector,
    eefr_related_party_selector, eefr_change_type_selector, balance_report, ereports, profit_report, accounts_report,
    income_expense_report, unpaid_bills_report
};

export type AccountingApplicationUnit = 'accounts' | 'general_ledger' | 'detail_accounts_selector'
    | 'ledger_types' | 'detail_accounts_selector'
    | 'eefr_economic_content_selector' | 'eefr_data_presentment_selector'
    | 'eefr_asset_group_selector' | 'eefr_related_party_selector'
    | 'eefr_change_type_selector'
    | 'balance_report' | 'profit_report' | 'accounts_report' | 'income_expense_report'
    | 'unpaid_bills_report'
    | 'ereports'
    ;