const messages = {
    ' ': ' ',   // space is used as a placeholder for empty translations

    "units": "Units",
    signature: "Signature",

    invoice_recipient: 'Billing Address',
    invoice_reg_code: 'Reg.no',
    invoice_vat_code: 'Vat.no',
    invoice_total: 'Total Amount',
    invoice_condition: 'Payment Terms',
    invoice_payment_conditions_long: 'The invoice is to be paid in {due_period} days after issuing.',
    invoice_payment_interest_long: 'In case of a delay in payment, a default interest of {interest}% per day is charged for each day of delay.',
    invoice_issuer_signature: 'Invoice issued by',
    invoice_recipient_signature: "Received by",
    article_or_service_name: 'Article/Service name',
    price: 'Rate',
    sum: 'Amount',
    total_without_vat: 'Net Amount',
    vat_short: 'VAT',
    date: 'Date',
    due_date: 'Due Date',
    and: 'and',
    number_in_words: 'Amount in words',
    invoice_payment_conditions_no_vat: 'This sale is zero rated.',
    invoice_no: 'Invoice No',
    due_date_alt: 'Due Date',
    doc_privacy_title: 'Privacy Policy',
    doc_terms_of_service_title: 'Terms of Service',

    btn_new: "Add new",
    btn_save: "Save",
    btn_delete: "Delete",
    btn_print: "Print",
    btn_undo: "Undo",
    btn_login: "Log in",
    btn_sign_in_google: "Sign in with Google",
    btn_cancel: 'Cancel',
    btn_refresh: 'Refresh',
    btn_remove: 'Remove',
    btn_create: 'Create',
    btn_dismiss: 'Close',
    btn_back: 'Back',
    btn_yes: 'Yes',
    btn_no: 'No',
    btn_add_user: 'Add user',
    btn_invite_new_user: 'Invite new user',
    btn_copy: 'Open copy',
    btn_ok: 'OK',
    btn_download: 'Download',
    btn_email: 'Send by email',
    btn_setup: 'Settings',
    btn_go_home: 'Go to home',
    info: 'Info',

    app_title: "Profit",
    code: "Code",
    name: "Name",
    memo: "Additional info",
    frontpage: "Front page",
    companies: "Companies",
    accounts: "Account plan",
    banks: "Banks",
    purchaseinvoices: "Purchase invoices",
    currencies: "Currencies",
    warehouses: "Warehouses",
    objects: "Objects",
    articles: "Articles",
    reg_code: "Reg. code",
    vat_code: "VAT code",
    type_id: "Type",
    logout: "Log out",
    nr: "Nr",
    document_date: "Document date",
    companyCode: "Company code",
    companyName: "Company name",
    total: "Total",
    currency: "Currency",
    currencyRateLabel: "Rate",
    amount_to_pay: "Unpaid",
    statusLabel: "Status",
    waybillNumbers: "Waybill numbers",
    summary_rows: "Row summary",
    summary_obj: "Object summary",
    payments: "Payments",

    created_datetime: "Created",
    creator: "Creator",
    modified_datetime: "Modified",
    modifier: "Modifier",
    attsCnt: "Attachments",
    edit_sales_title: "Sales",
    edit_address_title: "Address",
    edit_contacts_title: "Contact details",
    edit_primary_title: "Primary data",
    edit_setup_title: "Settings",
    setup_general_title: "General settings",
    grid_companycontacts: "Contacts",
    grid_bank_accounts: "Bank accounts",
    grid_salesprices: 'Sales prices',
    grid_purchaseprices: 'Purchase prices',
    grid_purchaseinvoice_rows: 'Articles',
    company_id: 'Company',
    supplierCode: 'Supplier code',
    purchasePrice: 'Purchase price',
    pricelist_id: 'Price list',
    customer_account_id: "Customer account",
    supplier_account_id: "Supplier account",
    customer: "Customer",
    supplier: "Supplier",
    edit_attributes_title: "Additional info",
    phone: "Phone",
    email: "Email",
    fax: "Fax",
    contact_person: "Contact person",
    legal_address_street: "Street",
    legal_address_city: "City",
    legal_address_country_id: "Country",
    legal_address_postal_code: "Postal code",
    postal_address_street: "Street",
    postal_address_city: "City",
    postal_address_country_id: "Country",
    postal_address_postal_code: "Postal code",
    legal_address_title: "Legal address",
    postal_address_title: "Postal address",
    countries: "Countries",
    prefix: "Prefix",
    password: 'Password',
    eu_code: "EU code",
    account_group_id: "Group",
    "account.group.activa": "Assets",
    "account.group.passiva": "Liabilities",
    "account.group.income": "Income",
    "account.group.expense": "Expense",
    "account.type.detail": "Detailed",
    "account.type.summary": "Summary",

    salesinvoices: "Sales invoices",

    buyer_id: "Buyer",
    due_period: "Payment term",
    reference_number: 'Reference number',
    wh_id: 'Warehouse',
    interest: "Interest %",
    currency_Id: "Currency",
    rate: 'Rate',
    drate: 'Rate',
    nrpefix: 'Prefix',
    nrsuffix: 'Suffix',
    summaryHeader: 'Summary',
    article_id: 'Item',
    article_name: 'Item name',
    quantity: 'Quantity',
    rounding: 'Rounding',
    nrprefix: 'Prefix',
    IBAN: 'IBAN',
    alert_document_loading: 'Loading document',
    alert_document_failed: 'Error',
    alert_document_saved: 'Document saved',
    alert_document_saving: 'Saving document',
    unit_id: 'Unit',
    vats: 'VAT rates',
    vat_id: 'VAT%',
    vat_total: 'VAT',
    total_vat: 'VAT total',
    correction: 'Correction',
    vat_return: 'VAT return',
    rowTotal: 'Row total',
    obj_id: 'Object',
    msg_delete_selected_rows: 'Delete selected rows?',
    question: 'Question',
    msg_delete_document: 'Delete document?',
    'Field code should be set when adding new entry': 'Code must be set when adding new entry',
    'Not unique': 'Not unique',
    'Validation errors': 'Cannot save, document is incomplete',
    'Required': 'Required',
    'Currency rate must not be 0': 'Currency rate must not be 0',
    'VAT applied': 'VAT applied',
    totals_vat_applied_to: 'VAT applied {vat_percent}%',
    totals_vat_total: 'VAT {vat_percent}% total',
    'total without vat': 'Total without VAT',
    'total with vat': 'Total with VAT',
    bank_id: 'Bank',
    'contact': 'Contact',
    'payment_period': 'Payment term',
    'unit': 'Unit',
    'taxed with': 'Taxed with',
    'bank.requisits': 'Bank details',
    'reg.code': 'Reg. code',
    'vat.code': 'VAT code',
    'payer': 'Payer',

    'Failed to fetch': 'Failed to fetch data from server',
    'buyer': 'Buyer',
    unsaved_changes_prompt: 'Leave without saving?',
    'register': 'Register',
    'my enterprises': 'My enterprises',
    'new enterprise': 'New enterprise',
    'storage dashboard': 'Stored data',
    debug: 'Debug',
    discount: '-%',
    'company data': 'Company data',
    company_data_tooltip: 'View/edit company data',
    not_found_add: 'Not found, add',
    'companyExtras': 'Company extra info',
    user_menu: 'Account settings',
    'settings': 'Settings',
    refresh: 'Refresh',
    'billing data': 'Billing data',
    'goto enterprise': 'Open company',

    'invite user': 'Invite user',
    'enterprise name': 'Company name',
    'enterprise type': 'Company type',
    'default estonian oy': 'Default estonian company (OÜ)',
    'empty database': 'Empty database',
    'database creation error': 'Database creation failed',
    'unknown error': 'Unknown error',
    'database creation error message': 'We have informed the technical support team. Please try again later.',
    'database creation in progress': 'Database creation in progress',
    status: 'Status',
    waiting: 'Waiting',
    creating: 'Creating',
    'tabase successfully created': 'Database successfully created',
    type_ext_id: 'Type',
    'article.typeExt.expense': 'Expense article',
    'article.typeExt.product': 'Warehouse article',
    financial_block_title: 'Finance',
    sales_block_title: 'Sales',
    barcode: 'Barcode',
    pricelists: 'Price lists',
    articlegroups: 'Product groups',
    account_id: 'Account',
    purchase_account_id: 'Purchase account',
    purchase_vat_id: 'Purchase VAT rate',
    parent_id: 'Parent group',
    articlegroup_id: 'Product group',
    sidebar_show: 'Show sidebar',
    sidebar_hide: 'Hide sidebar',
    salesinvoices_due_report: 'Unpaid invoices report',
    sales_report_title: 'Sales report {period_start} - {period_end}',
    sales_report: 'Sales report',
    period_start: 'Period start',
    period_end: 'Period end',
    payer_id: 'Payer',

    total_with_vat: 'Total with VAT',
    invoice_type_general: 'General invoice',
    invoice_type_cash: 'Cash invoice',
    invoice_type_card: 'Card payment invoice',
    articlegroup: 'Item group',
    types: 'Types',
    sort_order: 'Order',

    'doc_date, buyer_name, doc_nr': 'Date, buyer, no',
    'buyer_name, doc_date, doc_nr': 'No, date, buyer',
    'doc_date, doc_nr, buyer_name': 'Date, no, buyer',

    setup_additional_title: 'Additional settings',

    'FIRMA.NAME': 'Company name',
    'FIRMA.KOOD': 'Registration code',
    'FIRMA.KKNR': 'VAT payer no',
    'FIRMA.AADRESS': 'Address',
    'FIRMA.TELEFON': 'Phone',
    'FIRMA.EMAIL': 'Email',
    'FIRMA.VALUUTA': 'Base currency',
    'FIRMA.VALUUTA2': 'Second base currency',
    'DEF.FIRMAD.HINNAKIRIID': 'Base price list',
    'DEF.FIRMAD.MAKSETAHTAEG': 'Default payment term',
    'DEF.FIRMAD.HANKIJAKONTOID': 'Supplier account',
    'DEF.FIRMAD.KLIENDIKONTOID': 'Customer account',
    'DEF.FIRMAD.KMLIIKID': 'VAT type',
    'DEF.FIRMAD.KMDINF': 'VAT info',

    'EMAIL.SMTP.SERVER': 'Mail server (SMTP)',
    'EMAIL.SMTP.PORT': 'Mail server port',
    'EMAIL.SMTP.UID': 'Mail server user',
    'EMAIL.SMTP.PWD': 'Mail server password',
    'EMAIL.SMTP.TLS': 'Security settings',
    'EMAIL.USESMTP': 'Use SMTP',
    'EMAIL.SMTP.BCC': 'Always send blind copy (BCC)',
    'EMAIL.SMTP.SIGNATURE': 'Email signature',
    'EMAIL.SUBJECT.DEFAULT': 'Default email subject',

    'FIRMA.RIIK': 'Country',
    report_run_pdf: 'Preview',
    report_run_pdf_tooltip: 'Report preview in PDF format',
    article: 'Articles',
    object: 'Object',
    vat: 'VAT rate',
    article_group: 'Articles group',
    'currency selector': 'Currency selection',
    'interest editor': 'Interest change',
    'unit visible': 'Unit visible',
    'unit editable': 'Unit editable',
    'vat visible': 'VAT visible',
    'vat editable': 'VAT editable',
    'discount visible': 'Discount column',
    'total w/o vat visible': 'Total without VAT',
    'total vat visible': 'VAT total',
    'total with vat visible': 'Total with VAT',
    'show revenues in totals': 'Show revenues in totals',
    obj_visible: 'Object column',
    invoice_nr_visible: 'Invoice no',
    due_date_visible: 'Due date',
    interest_visible: 'Interest column',
    invoice_nr: 'Invoice no',

    colorscheme_light: 'Light color scheme',
    colorscheme_dark: 'Dark color scheme',
    colorscheme_system: 'System color scheme',
    density_default: 'Default density',
    density_comfortable: 'Comfortable density',
    density_compact: 'Compact density',

    msg_error_loading_links: 'Failed to load links',

    doc_date: 'Date',
    buyer_name: 'Buyer',
    payer_name: 'Payer',
    currency_code: 'Currency',
    currency_rate: 'Rate',
    waybills: 'Waybills',
    to_pay: 'Unpaid',
    summaryrows: 'Row info',
    locked_icon: '🔒',
    attachment_icon: '📎',
    label_icon: '🏷️',
    emailed_status_icon: '📧',
    sender_login: 'Creator',
    grid_memo: 'Additional field',
    d_created: 'Created',
    d_modified: 'Modified',
    mdatts: 'Attachments',
    labels: 'Labels',

    setup_users_title: 'Users',
    setup_companies_title: 'Companies',
    setup_company_title: 'Company settings',
    setup_printforms_title: 'Print forms',
    setup_dangerous_title: 'Dangerous settings',
    setup_dangerous_warning: 'The following settings are potentially dangerous. Make sure you are sure you want to continue with them.',
    setup_tech_title: 'Technical settings',
    setup_users_no_database: 'No database',
    title_add_new_user: 'Add new user',
    select_new_user_name: 'Select new user name',
    tooltip_invite_new_user: 'Can\'t find the user? Invite a new user by email',

    email_invite_new_user_subject: 'I invite you to use Profit accounting software',
    email_invite_new_user_body: 'TODO here comes the content of the email',
    msg_remove_user: 'Are you sure you want to delete the user?',
    msg_hello: 'Hello {name}',
    msg_no_name: 'Please enter a name by which other users will see you. \nWithout a name, you cannot be invited or found.',
    lbl_enter_your_user_name: 'Enter your name',
    msg_user_name_default: 'Username must be unique, consist of at least 3 characters and can only contain letters, numbers, dots and underscores.',
    msg_user_name_too_short: 'Username must be at least 3 characters long.',
    msg_user_name_too_long: 'Username must not be longer than 20 characters.',
    msg_uniqueness_checked_on_save: 'Uniqueness is checked on save.',
    msg_not_shared: 'The company database is not shared with anyone.',
    btn_edit_name: 'Change name',
    btn_cancel_edit_name: 'Cancel name change',

    db_status_ready: 'ready',
    db_status_waiting: 'waiting for creation',
    db_status_creating: 'creating',
    db_status_error: 'error',
    db_status_updating: 'updating',

    msg_loading_databases_list: 'Loading database data',
    msg_no_databases: 'No companies found, create a new one or wait until someone shares their company data with you.',
    msg_cant_create_no_name: 'Cannot create new databases because username is missing.',
    btn_lock: 'Lock',
    btn_unlock: 'Unlock',
    btn_filter: 'Filter',
    filter: 'Filter',

    doc_date_from: 'Period start',
    doc_date_until: 'Period end',
    btn_close: 'Close',
    total_from: 'Total from',
    total_until: 'Total until',
    object_id: 'Object',
    currency_id: 'Currency',
    filter_active: 'Filter active',
    selected: 'row selected',
    payer_selector: 'Payer selection',
    btn_register: 'Create account',
    version: 'Version {version}',
    db_status_archiving: 'Archiving',
    msg_archive_db: 'Are you sure you want to delete the database?',
    btn_delete_db: 'Delete database',
    msg_database_archiving_long: 'The database is set for deletion.',
    title_database_archiving: 'Database on deletion',
    menu_archived_visible: 'Show deleted databases',
    menu_archiving_visible: 'Show databases on deletion',
    title_database_archived: 'Deleted database',
    msg_database_archived_long: 'The database has been deleted.',
    msg_setup_users_shared_database: 'You are not the owner of this database',
    msg_report_error: 'Error during report generation:{br}{error}',

    shorthelp_salesinvoices_nr: 'Sales invoice number.{br}Automatically generated number, can be changed',
    shorthelp_salesinvoices_salesinvoicerows_vat_id: 'VAT rate.{br}VAT rate used on the invoice',
    shorthelp_salesinvoices_memo: 'Additional information about the sales invoice.{br}Not displayed on the invoice',
    shorthelp_salesinvoices_salesinvoicerows_discount: 'Discount.{br}Row discount percentage',
    shorthelp_salesinvoices_salesinvoicerows_price: 'Price.{br}Price of article without VAT, the program may suggest a sales price according to the buyer\'s price list',
    shorthelp_salesinvoices_salesinvoicerows_unit_id: 'Unit.{br}Unit of article',
    shorthelp_salesinvoices_salesinvoicerows_quantity: 'Quantity.{br}Quantity of article, default is 1',
    shorthelp_salesinvoices_salesinvoicerows_article_name: 'Name of article.{br}Name of article, defaults from the article card.{br}Name can be changed',
    shorthelp_salesinvoices_salesinvoicerows_article_id: 'Articles.{br}Article selection',
    shorthelp_row__sel: 'Select row.{br}Selected rows can be deleted',
    shorthelp_salesinvoices_due_date: 'Payment date.{br}Depends on the invoice date and due date.{br}To change, modify the due date',
    shorthelp_salesinvoices_due_period: 'Due date.{br}Due date from the invoice issue date in days.{br}Default value comes from buyer data',
    shorthelp_salesinvoices_date: 'Invoice date.{br}Invoice issue date',
    shorthelp_salesinvoices_buyer_id: 'Buyer selection.{br}Active clients from the companies list are available for selection.{br}If the buyer is missing, it can be added.{br}For this, enter the buyer\'s name and press "Add ..." in the dropdown menu{br}With the "..." button you can view or modify buyer data.',

    shorthelp_salesinvoices_filter_doc_date_from: 'Filtered period start.{br}Invoices with a date greater or equal are displayed',
    shorthelp_salesinvoices_filter_doc_date_until: 'Filtered period end.{br}Invoices with a date less or equal are displayed',
    shorthelp_salesinvoices_filter_total_from: 'Amount from.{br}Invoices with an amount greater or equal are displayed',
    shorthelp_salesinvoices_filter_total_until: 'Amount to.{br}Invoices with an amount less or equal are displayed',
    shorthelp_salesinvoices_filter_article_id: 'Articles filter.{br}Invoices with the selected articles are displayed',
    shorthelp_salesinvoices_filter_object_id: 'Object filter.{br}Invoices with the selected object are displayed',
    shorthelp_salesinvoices_filter_buyer_id: 'Buyer filter.{br}Invoices of the selected buyer are displayed',
    shorthelp_salesinvoices_filter_payer_id: 'Payer filter.{br}Invoices of the selected payer are displayed',
    shorthelp_salesinvoices_filter_currency_id: 'Currency filter.{br}Invoices in the selected currency are displayed',
    shorthelp_salesinvoices_filter_active: 'Filter only works when this checkbox is enabled',
    shorthelp_sales_report_period_start: 'Report period start',
    shorthelp_sales_report_period_end: 'Report period end',
    shorthelp_sales_report_buyer_id: 'If selected, only invoices of the selected buyer remain in the report',
    shorthelp_sales_report_payer_id: 'If selected, only invoices of the selected payer remain in the report',
    shorthelp_sales_report_supplier_id: 'If selected, only invoices with articles from the selected supplier remain in the report',
    shorthelp_sales_report_article_id: 'If selected, only invoices with the selected articles remain in the report',
    shorthelp_sales_report_object_id: 'If selected, only invoices with the selected object remain in the report',
    shorthelp_sales_report_vat_id: 'If selected, only invoices with the selected VAT remain in the report',
    shorthelp_sales_report_grp_id: 'If selected, only invoices containing articles belonging to the selected articles group remain in the report',
    shorthelp_sales_report_type_general: 'Whether to show transfer invoices{br}If all invoice type checkboxes are removed, all invoices are displayed',
    shorthelp_sales_report_type_cash: 'Whether to show cash invoices{br}If all invoice type checkboxes are removed, all invoices are displayed',
    shorthelp_sales_report_type_card: 'Whether to show card payment invoices{br}If all invoice type checkboxes are removed, all invoices are displayed',
    shorthelp_sales_report_sort_order: 'Invoice order selection',

    shorthelp_companies_name: 'Company name',
    shorthelp_companies_code: 'Company code{br}Unique code used for internal purposes in the program, e.g., in company selections.',
    shorthelp_companies_reg_code: 'Registry code'
        + '{br}Automatically filled if the company information comes from the business register',
    shorthelp_companies_vat_code: 'VAT payer number'
        + '{br}Automatically filled if the company information comes from the business register',
    shorthelp_companies_supplier: 'Is a supplier{br}If the checkbox is enabled, it is a supplier{br}Suppliers come to the company selection in the purchase invoice, purchase order, etc.',
    shorthelp_companies_customer: 'Is a customer{br}If the checkbox is enabled, it is a customer{br}Customers come to the company selection in the sales invoice, sales order, etc.',

    shorthelp_companies_payment_period: 'Payment term{br}Payment term from the invoice issue date in days.{br}Comes by default to the invoice.',
    shorthelp_companies_interest: 'Interest rate{br}Comes to the sales invoice',
    shorthelp_companies_currency_id: 'Preferred currency in which the given partner is invoiced{br}Comes by default to the sales invoice',

    shorthelp_companies_legal_address_street: 'Legal address: street, house, apartment'
        + '{br}e.g., Kivi 21-1.'
        + '{br}Automatically filled if the company information comes from the business register',
    shorthelp_companies_legal_address_city: 'Legal address: city'
        + '{br}e.g., Tartu city, Tartu city, Tartu County'
        + '{br}Automatically filled if the company information comes from the business register',
    shorthelp_companies_legal_address_postal_code: 'Legal address: postal code{br}e.g., 12345'
        + '{br}Automatically filled if the company information comes from the business register',
    shorthelp_companies_legal_address_country_id: 'Legal address: country{br}e.g., Estonia',

    shorthelp_companies_postal_address_street: 'Postal address: street, house, apartment{br}e.g., Kivi 21-1',
    shorthelp_companies_postal_address_city: 'Postal address: city{br}e.g., Tartu city, Tartu city, Tartu County',
    shorthelp_companies_postal_address_postal_code: 'Postal address: postal code{br}e.g., 12345',
    shorthelp_companies_postal_address_country_id: 'Postal address: country{br}e.g., Estonia',
    shorthelp_companies_phone: 'Phone number{br}e.g., +372 1234567',
    shorthelp_companies_email: 'Email address{br}e.g., info@intellisoft.ee',
    shorthelp_companies_contact_person: 'Main contact person details{br}See the contact list below',
    shorthelp_companies_fax: 'Fax number{br}e.g., +372 1234567',
    shorthelp_companies_customer_account_id: 'Customer account number in the financial module'
        + '{br}The default value comes from the general settings',
    shorthelp_companies_supplier_account_id: 'Supplier account number in the financial module'
        + '{br}The default value comes from the general settings',

    shorthelp_companies_companycontacts_prefix: 'Contact prefix{br}e.g., Mr, Ms, Dr, Prof',
    shorthelp_companies_companycontacts_contact: 'Contact name{br}e.g., Jaan Tamm',
    shorthelp_companies_companycontacts_phone: 'Contact phone number{br}e.g., +372 1234567',
    shorthelp_companies_companycontacts_email: 'Contact email address',
    shorthelp_companies_companycontacts_memo: 'Short additional information related to the contact',
    shorthelp_companies_bank_accounts_bank_id: 'Bank selection',
    shorthelp_companies_bank_accounts_IBAN: 'Bank account, IBAN',
    shorthelp_companies_bank_accounts_memo: 'Additional information about the bank account',
    shorthelp_articles_code: 'Item code{br}Unique code used for internal purposes in the program, e.g., in item selections.',
    shorthelp_articles_name: 'Item name{br}',
    shorthelp_articles_type_ext_id: 'Item type{br}The type of item determines how the item is handled{br}e.g., in item selections, inventory accounting, sales invoices, etc.',
    shorthelp_articles_unit_id: 'Unit of measure',
    shorthelp_articles_articlegroup_id: 'Item group{br}The item group data may provide default account and tax settings for the item',
    shorthelp_articles_memo: 'Additional info for internal use',
    shorthelp_articles_barcode: 'Item barcode or EAN code',
    shorthelp_articles_salesprices_pricelist_id: 'Price list name',
    shorthelp_articles_salesprices_price: 'Price',
    shorthelp_articles_salesprices_discount: 'Discount percentage',
    shorthelp_articles_purchaseprices_company_id: 'Supplier',
    shorthelp_articles_purchaseprices_supplierCode: 'Item code in the supplier information system',
    shorthelp_articles_purchaseprices_purchasePrice: 'Purchase price',
    shorthelp_articles_account_id: 'Sales account',
    shorthelp_articles_purchase_account_id: 'Purchase account',
    shorthelp_articles_vat_id: 'Sales VAT rate',
    shorthelp_articles_purchase_vat_id: 'Purchase VAT rate',
    shorthelp_units_code: 'Unit code, unique, used in selections',
    shorthelp_units_name: 'Unit name',
    shorthelp_units_memo: 'Additional info for internal use',
    shorthelp_currencies_code: 'Currency code, unique, used in selections',
    shorthelp_currencies_name: 'Currency name',
    shorthelp_currencies_memo: 'Additional info for internal use',
    shorthelp_currencies_rate: 'Currency rate{br}If the base currency is EUR, then it is always 1',
    shorthelp_currencies_drate: 'Currency rate{br}The currency rate that is set relative to the base currency',
    shorthelp_countries_code: 'Country code, unique, used in selections',
    shorthelp_countries_name: 'Country name',
    shorthelp_countries_memo: 'Additional info for internal use',
    shorthelp_countries_eu_code: 'Country standard 2-letter code{br}See ISO 3166-1 Alpha-2 code:{br}https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes',
    shorthelp_accounts_code: 'Account code, unique, used in selections',
    shorthelp_accounts_name: 'Account name',
    shorthelp_accounts_memo: 'Additional info for internal use',
    shorthelp_accounts_accountGroupId: 'Account group{br}Assets, liabilities, income or expense',
    shorthelp_accounts_type_id: 'Account type{br}Entries can only be made with detailed accounts.{br}General accounts summarize sub-accounts with the same beginning.',
    swift: 'Swift',
    shorthelp_banks_code: 'Bank code, unique, used in selections',
    shorthelp_banks_name: 'Bank name',
    shorthelp_banks_memo: 'Additional info for internal use',
    shorthelp_banks_swift: 'Bank SWIFT code',
    shorthelp_banks_eInvoiceChannel: 'Bank e-invoice channel',  // TODO
    shorthelp_banks_address: 'Bank address',
    shorthelp_salesinvoices_currency_id: 'Sales invoice currency selection',
    shorthelp_salesinvoices_drate: 'Sales invoice currency rate',
    eInvoiceChannel: 'E-invoice channel',
    address: 'Address',

    shorthelp_warehouses_code: 'Warehouse code, unique, used in selections',
    shorthelp_warehouses_name: 'Warehouse name',
    shorthelp_warehouses_memo: 'Additional info for internal use',
    shorthelp_objects_code: 'Object code, unique, used in selections',
    shorthelp_objects_name: 'Object name',
    shorthelp_objects_memo: 'Additional info for internal use',
    shorthelp_vats_code: 'VAT rate code, unique, used in selections',
    shorthelp_vats_name: 'VAT rate name',
    shorthelp_vats_memo: 'Additional info for internal use',

    shorthelp_articlegroups_code: 'Item group code, unique, used in selections',
    shorthelp_articlegroups_name: 'Item group name',
    shorthelp_articlegroups_memo: 'Additional info for internal use',
    shorthelp_articlegroups_parent_id: 'Parent item group',
    shorthelp_articlegroups_account_id: 'Sales account{br}Used when adding a new item',
    shorthelp_articlegroups_vat_id: 'Sales VAT rate{br}Used when adding a new item',
    shorthelp_articlegroups_purchase_account_id: 'Purchase account{br}Used when adding a new item',
    shorthelp_articlegroups_purchase_vat_id: 'Purchase VAT rate{br}Used when adding a new item',
    shorthelp_pricelists_code: 'Price list code, unique, used in selections',
    shorthelp_pricelists_name: 'Price list name',
    shorthelp_pricelists_memo: 'Additional info for internal use',
    shorthelp_companies_pricelist_id: 'Default price list{br}Used when creating sales documents',

    tooltip_show_sidebar: 'Show sidebar',

    vat_report: 'VAT report',
    shorthelp_vat_report_period_start: 'Start date of the period{br}Mandatory',
    shorthelp_vat_report_period_end: 'End date of the period{br}Mandatory',
    vat_name: 'VAT rate',
    vat_percent: 'VAT %',
    msg_drop_files_here: 'Drag and drop files here to attach',
    msg_save_document_to_attach: 'Save document data to attach',

    unit_code: 'Unit',
    group_code: 'Group',
    price_with_vat: 'Price with VAT',
    sales_account: 'Sales account',
    purchases_account: 'Purchase account',
    wh_qty: 'Stock balance',
    wh_total: 'Stock balance €',
    reserved_purchase: 'Purchase reservation',
    reserved_sales: 'Sales reservation',
    available: 'Available balance',
    wh_min_qty: 'Min. stock',
    purchase_need: 'Purchase need',
    purchase_price: 'Purchase price',
    purchase_currency_code: 'Currency',
    supplier_code: 'Supplier code',
    color: 'Color',
    group_id: 'Group',
    supplier_id: 'Supplier',
    shorthelp_articles_filter_group_id: 'Displays items that belong to the selected item group',
    shorthelp_articles_filter_supplier_id: 'Displays items whose supplier is selected',
    shorthelp_articles_filter_active: 'Filter only works if this checkbox is enabled',

    article_type_ext_0: 'Stock item',
    article_type_ext_10: 'Production',
    article_type_ext_1000: 'Expense item',
    article_type_ext_2000: 'KÜ näit',   //TODO
    article_type_ext_3000: 'KÜ fixed fee',  //TODO
    company_type: 'Type',
    legal_address: 'Legal address',
    postal_address: 'Postal address',
    due_days: 'Payment term',
    due_date_grid: 'Payment term',
    contract: 'Contract',
    pricelist_name: 'Price list',
    shorthelp_articles_filter_article_type_ext_0: 'Displays warehouse articles',
    shorthelp_articles_filter_article_type_ext_10: 'Displays production articles',
    shorthelp_articles_filter_article_type_ext_1000: 'Displays expense articles',
    shorthelp_articles_filter_article_type_ext_2000: 'Displays HOA example',
    shorthelp_articles_filter_article_type_ext_3000: 'Displays HOA fixed fees',

    companygroups: 'Company groups',
    shorthelp_companies_filter_group_id: 'Displays companies that belong to the selected group',
    shorthelp_companies_filter_customers: 'Displays customers',
    customers: 'Customers',
    shorthelp_companies_filter_suppliers: 'Displays suppliers',
    suppliers: 'Suppliers',
    shorthelp_companies_filter_active_companies: 'Displays active companies',
    active_companies: 'Active companies',
    shorthelp_companies_filter_closed_companies: 'Displays closed companies',
    closed_companies: 'Closed companies',
    shorthelp_companies_filter_active: 'Filter only works if this checkbox is enabled',

    show_ui_shortHelp: 'Show short hints when the mouse is over an element',
    'shorthelp_localSetup_ui.showHints': 'Show short hints when the mouse is over an element',
    setup_user_title: 'User settings',

    vat_return_percent: 'Return %',
    grid_salesinvoicerows: 'Item rows',
    purchase_payment_title: 'Payment information',
    paid_in_cash: 'Paid in cash',
    payment_account_id: 'Payment from account',

    'due_period must be greater than or equal to 0': 'Due period cannot be less than zero',
    expense_date: 'Expense occurrence date',
    title_registries: 'Registries and reports',
    title_favourites: 'Favorites',
    title_notes: 'Notes',
    shortcut_quick_add: 'Quick add',
    edit_companygroups_title: 'Editing company group {title}',
    new_companygroups_title: 'New company group {title}',
    view_companygroups_title: 'Company group {title}',

    view_general_ledger_title: 'Entry {title}',

    edit_companies_title: 'Editing company "{title}"',
    new_companies_title: "New company {title}",
    view_companies_title: "Company {title}",

    edit_articles_title: 'Edit item {title}',
    new_articles_title: 'New item {title}',
    view_articles_title: 'Item {title}',

    edit_salesinvoices_title: 'Edit sales invoice {title}',
    new_salesinvoices_title: 'New sales invoice {title}',
    view_salesinvoices_title: 'View sales invoice {title}',
    new_salesinvoices_menu: 'Add sales invoice',

    edit_purchaseinvoices_title: 'Edit purchase invoice {title}',
    new_purchaseinvoices_title: 'New purchase invoice {title}',
    view_purchaseinvoices_title: 'View purchase invoice {title}',
    new_purchaseinvoices_menu: 'Add purchase invoice',

    edit_units_title: 'Editing unit {title}',
    new_units_title: 'New unit {title}',

    new_currencies_title: 'New currency {title}',
    edit_currencies_title: 'Edit currency {title}',

    edit_countries_title: 'Edit country {title}',
    new_countries_title: 'New country {title}',
    new_companies_menu: 'Add new company',
    new_articles_menu: 'Add new article',

    edit_accounts_title: 'Edit account {title}',
    new_accounts_title: 'New account {title}',

    new_banks_title: 'New bank {title}',
    edit_banks_title: 'Edit bank {title}',

    edit_warehouses_title: 'Edit warehouse {title}',
    new_warehouses_title: 'New warehouse {title}',

    edit_objects_title: 'Edit object {title}',
    new_objects_title: 'New object {title}',

    edit_vats_title: 'Edit VAT rate {title}',
    new_vats_title: 'New VAT rate {title}',

    new_articlegroups_title: 'New item group {title}',
    edit_articlegroups_title: 'Edit item group {title}',

    edit_pricelists_title: 'Editing price list {title}',
    new_pricelists_title: 'New price list {title}',

    new_ledger_types_title: 'New entry type {title}',
    edit_ledger_types_title: 'Edit entry type {title}',

    new_general_ledger_title: 'New entry {title}',
    edit_general_ledger_title: 'Edit entry {title}',

    ledger_type_id: 'Entry type',
    with_currency: 'Currency entry',
    document: 'Document',
    debit: 'Debit',
    credit: 'Credit',
    total_d: 'Total Debit',
    total_c: 'Total Credit',
    grid_entries: 'Entry rows',

    not_implemeted_msg: 'Functionality is not yet ready, use the old user interface',

    module_sales: 'Sales',
    module_purchases: 'Purchases',
    module_common: 'Common',
    module_accounting: 'Finance',

    general_ledger: 'General Ledger',
    ledger_types: 'Entry types',

    attachments: 'Attachments',
    drop_attachments: 'New attachment',


    objs: 'Objects',
    obj_on_rows: 'Different objects on rows',
    about: 'About the program',
    help: 'Help',
    gl_date: 'Expense date',
    supplier_code_company: 'Supplier code',
    supplier_name: 'Supplier name',
    summaryobj: 'Objects',
    document_modified: 'Document modified',

    logo_alignment: 'Logo position',
    logo_alignment_left: 'Left',
    logo_alignment_center: 'Center',
    logo_alignment_right: 'Right',
    logo_width: 'Logo width (%)',

    ledger_type_code: 'ET',
    ledger_type_name: 'Entry type',
    fiscal_period_code: 'Fiscal year',
    doc: 'Document',
    unbound: 'Unbound',
    status_id: 'Status',
    debit_base: 'Debit base',
    credit_base: 'Credit base',
    total_unbound: 'Total unbound',
    module_capital_assets: 'Fixed Assets',
    ca_groups: 'Fixed Asset Groups',
    edit_ca_groups_title: 'Edit Fixed Asset Group {title}',
    new_ca_groups_title: 'New Fixed Asset Group {title}',
    edit_capital_assets_title: 'Edit Fixed Asset {title}',
    new_capital_assets_title: 'New Fixed Asset {title}',
    capital_assets: 'Fixed Assets',
    purchase_date: 'Purchase Date',
    amort_start_date: 'Amortization Start Date',
    purchase_value: 'Acquisition Cost',
    residue_value: 'Residual Value',
    calculation_method: 'Calculation Method',
    ca_method_linear: 'Linear',
    ca_method_withoud_amortisation: 'Without Amortization',
    amort_percent: 'Amortization %',
    asset_account_id: 'Fixed Asset Account',
    amort_account_id: 'Amortization Account',
    wear_account_id: 'Depreciation Account',
    grid_ca_modifications: 'Modifications',
    amort_date: 'Amortization Date',
    modification: 'Modification',
    percent: 'Percent',
    writeoff_block_title: 'Write-off',
    writeoff_date: 'Write-off Date',
    writeoff_amort_date: 'Amortization Date',
    writeoff_memo: 'Write-off Additional Info',
    ca_amortisation_report: 'Amortization Report',
    initial_value: 'Initial Value',
    value_modification: 'Revaluation',
    amortisation: 'Amortization',
    capital_asset_id: 'Fixed Asset',
    group_name: 'Group',
    purchase_date_from: 'Purchase Date From',
    purchase_date_until: 'Purchase Date Until',
    writeoff_date_from: 'Write-off Date From',
    writeoff_date_until: 'Write-off Date Until',
    e_invoice_setup_title: 'E-Invoices',
    e_invoice_operator: 'Operator',
    e_invoice_auth_key: 'Authentication Key',
    e_invoice_environment: 'Environment',
    e_invoice_attach_pdf: 'Attach Own PDF Invoice',

    e_invoice_operator_finbite: 'Finbite',
    e_invoice_operator_rik: 'E-Invoicer / RIK',
    not_used: 'Not Used',
    e_invoice_environment_test: 'Test Environment',
    e_invoice_environment_live: 'Live Environment',
    e_invoice_setup_sales: 'Sales Invoices',
    e_invoice_setup_purchases: 'Purchase Invoices',
    e_invoice_setup_rik: 'E-Invoicer / RIK',
    e_invoice_setup_finbite: 'Finbite',
    e_invoice_setup_sales_parties: 'Companies on E-Invoice',
    e_invoice_buyer_party: 'BuyerParty',
    e_invoice_payer_party: 'PayerParty',
    e_invoice_recipient_party: 'RecipientParty',
    not_exported: '--Not Exported--',
    e_invoice_party_buyer: 'Buyer',
    e_invoice_party_payer: 'Payer',
    purchases_report: 'Purchases Report',


    msg_error_processing_link: 'Error processing link',
    error: 'Error',

    e_invoice_setup_general: 'General',

    percent_symbol: '%',
    ca_amortisation_report_title: 'Amortization report {period_start} - {period_end}',
    purchases_report_title: 'Purchases report {period_start} - {period_end}',

    module_warehouse: 'Warehouse',
    wh_inits: 'Initial state input',
    wh_code: 'Warehouse',
    wh_name: 'Warehouse name',
    dst_wh_id: 'Destination warehouse',
    total_cost: 'Total warehouse cost',

    new_wh_inits_title: 'New initial state {title}',
    view_wh_inits_title: 'View initial state {title}',
    edit_wh_inits_title: 'Edit initial state {title}',
    grid_waybillrows: 'Waybills',
    cost: 'Warehouse cost',
    wh_incomes: 'Warehouse incomes',
    edit_wh_incomes_title: 'Warehouse income {title}',
    new_wh_incomes_title: 'New warehouse income {title}',
    view_wh_incomes_title: 'View warehouse income {title}',

    wh_outcomes: 'Warehouse outcomes',
    customer_name: 'Customer',
    total_price: 'Total sales price',
    edit_wh_outcomes_title: 'Warehouse outcome {title}',
    new_wh_outcomes_title: 'New warehouse outcome {title}',
    view_wh_outcomes_title: 'Warehouse outcome {title}',
    src_wh_id: 'Source warehouse',
    customer_id: 'Customer',
    recipient_id: 'Recipient',

    total_cost_from: 'Total warehouse cost from',
    total_cost_until: 'Total warehouse cost until',
    total_price_from: 'Total sales price from',
    total_price_until: 'Total sales price until',

    wh_intra: 'Inter-warehouse movements',

    wh_src_name: 'Source warehouse',
    wh_dst_name: 'Destination warehouse',
    wh_src_id: 'Source warehouse',
    wh_dst_id: 'Destination warehouse',

    "Can't lock this type of document": 'This type of document cannot be locked',
    edit_wh_intra_title: 'Inter-warehouse movement {title}',
    new_wh_intra_title: 'New inter-warehouse movement {title}',
    view_wh_intra_title: 'Inter-warehouse movement {title}',
    wh_writeoffs: 'Write-offs',

    edit_wh_writeoffs_title: 'Warehouse write-off {title}',
    new_wh_writeoffs_title: 'New warehouse write-off {title}',
    view_wh_writeoffs_title: 'Warehouse write-off {title}',

    default_print_form: 'Default print form',
    report_form: 'Print form',

    invoicing_title: 'Invoicing',   //TODO is it?

    unit_integers_singular: 'Unit (singular)',
    unit_decimals_singular: 'Cent unit (singular)',
    unit_integers_plural: 'Unit (plural)',
    unit_decimals_plural: 'Cent unit (plural)',
    logo: 'Logo',


    emails: 'Emails',
    addr_to: 'Recipient',
    addr_cc: 'Copy (CC)',
    addr_bcc: 'Blind copy (BCC)',
    subject: 'Subject',
    body: 'Content',
    sent_date: 'Sent',
    new_emails_title: 'New email {title}',
    edit_emails_title: 'Email {title}',
    view_emails_title: 'Email {title}',

    setup_user_ui_title: 'User Interface',
    setup_email_title: 'Email',

    no_tls: 'No TLS',
    implicit_tls: 'Automatic TLS (implicit)',
    explicit_tls: 'Manual TLS (explicit)',
    require_tls: 'TLS required (require)',
    change_password: 'Change password',
    new_password: 'New password',
    change_password_msg: 'Enter new password and press OK',
    email_not_sent: 'Email not sent',
    email_sent: 'Email sent',

    msg_loading_privileges: 'Loading privileges',
    msg_no_read_privilege: 'No read access',
    language: 'Language',
    lang_et: 'Eesti keeles',
    lang_en: 'In English',

    error_attachment_unique_violation: 'Attachment with name {name} is already added',
    title_error: 'Error',
    'Field nr should be set when adding new entry': 'Number must be set when adding a new entry',
    'Date is required': 'Date is required',
    title_additional_info: 'Additional info',
    cn_code: 'CN code',
    name2: 'Name in another language',
    grid_unit_conversions: 'Conversion rules',
    converted_unit_id: 'Converted unit',
    coef: 'Coefficient',
    title_no_read_privilege: 'No read access',
    eefr_economic_content: 'Economic content',
    eefr_data_presentment: 'Data presentation method',
    eefr_card_title: 'Annual report data',
    grid_eefr_company_related_parties: 'Related parties',
    eefr_related_party: 'Related party',
    eefr_asset_group: 'Asset group',
    eefr_change_type: 'Change type',
    from: 'From',
    until: 'Until',
    'User not found': 'User not found',
    eefr_report: 'Annual report data (RIK)',
    account_main_id: 'MS',
    account_main_desc: 'Economic content',
    dp_id: 'AEV',
    ag_id: 'VG',
    ct_id: 'ML',
    rp_id: 'SOP',

    subaccount_aev_desc: 'aev desc',
    account_code: 'Account code',
    d_base: 'Debit',
    c_base: 'Credit',
    eefr_report_title: 'Annual report data {period_start} - {period_end}',
    has_eefr: 'Has annual report data',
    title_salesinvoices_due: 'Unpaid sales invoices',
    more: 'More',
    'error loading setup': 'Error loading setup',
    create_company_database_title: 'Create company database',
    create_company_database_msg: 'Enter the company database name and type and press OK',
    tip_enter_company_name: 'Enter the company name (minimum length 3 characters)',
    tip_company_name_too_short: 'Company name is too short',
    to_frontpage: 'To front page',
    msg_user_name_not_unique: 'This username is already taken',
    msg_user_name_invalid: 'Username can only contain letters, numbers, dots and underscores',
    billing_report: 'Billing report',
    billing_dt: 'Date',
    amount: 'Amount',
    company: 'Company',
    doc_type: 'Document type',
    doc_nr: 'Document number',

    'database created': 'Database created',
    'sales': 'Sales',

    'auth/invalid-email': 'Invalid email address',
    'auth/missing-password': 'Password is required',
    'auth/user-not-found': 'User not found',
    'auth/wrong-password': 'Wrong password',
    page_not_found: 'Not found',
    page_not_found_message: 'The page {path} was not found',

    // 2024-04-26
    unknown_page: 'Unknown page',
    msg_unknown_page: 'Page not found: {regName}',
    doc_load_error: 'Document loading error',
    doc_load_error_message: 'Error loading document',
    doc_index_title: 'Friendly business software',
    hint_language: 'Language selection',
    lang_ru: 'По-русски',
    prices: 'Prices',
    package: 'Package',
    billing_package_sales: 'Sales',
    billing_package_sales_description: 'Sales invoices, customer management, price lists, connection with financial module',
    doc_prices_title: 'Prices',
    eur: 'EUR',
    eur_symbol: '€',
    'auth/weak-password': 'The password is too weak, it must be at least 6 characters',
    'auth/email-already-in-use': 'Email address is already in use',
    'auth/invalid-login-credentials': 'Incorrect username or password',
    msg_already_have_account: 'Already have an account?',
    msg_register_info: 'By clicking "Create account" you agree to our ',
    agree_to_terms: 'Terms of Use',
    agree_to_privacy: 'Privacy Policy',
    login_header: 'Log in',
    reset_password_link: 'Forgot your password?',
    reset_password_email_sent: 'We sent an email with instructions on how to reset your password',
    msg_no_account: 'Not a Profit user yet?',
    billing_package_purchases: 'Purchases',
    billing_package_purchases_description: 'Purchase invoices, supplier management, connection with financial module',
    'No bank account found for sender': 'No bank account found for sender',

    setup_bank_accounts_title: 'Bank Accounts',
    setup_update_db_title: 'Database Update',
    own_bank_accounts: 'Bank Accounts',
    paymenttypes: 'Payment Methods',
    edit_paymenttypes_title: 'Edit Payment Method {title}',
    new_paymenttypes_title: 'New Payment Method {title}',
    prefer_for_bank_outcome: 'Preferred for Payments',
    print_on_bank_requisits: 'Print on Bank Requisites',
    cashorder: 'Cash Order',
    bank_account: 'Bank Account No',
    iban: 'IBAN',
    title_report_loading_error: 'Error Loading Report',
    msg_report_loading_error_long: 'Report Loading Failed. {error}',
    log_entry: 'Log Entry',
    btn_copy_to_clipboard: 'Copy to Clipboard',
    btn_copied_to_clipboard: 'Copied to Clipboard',
    priority: 'Priority',
    setup_warehouse_title: 'Warehouse',
    
    'LADU.AUTOSTL.MYYK': 'Automatic Delivery Notes from Sales Invoices',
    'LADU.AUTOSTL.OST': 'Automatic Delivery Notes from Purchase Invoices',
    
    report_run_table: 'Table',
    report_run_table_tooltip: 'Report Preview in Table Format',
    report_run_json: 'JSON',
    report_run_json_tooltip: 'Report Output in JSON Format',
    
    eefr_data: 'MAA Data (RIK)',
    eefr_section_EE0301010: 'Microenterprise Data Section Balances EE0301010',
    eefr_section_EE0301020: 'Standard Data Section Balances EE0301020',
    eefr_section_EE0302010: 'Period Revenues EE0302010',
    eefr_data_sections: 'Data Sections',
    
    close_preview: 'Close Preview',
    attachments_inline_preview_setup: 'Attachments Preview Setup',
    ui_att_inline: 'Enable Attachments Preview on Document Card',
    ui_hints_show: 'Show Short Hints When Mouse is Over Element',
    ui_att_inline_right: 'Attachments Preview on Right if Possible',
    ui_att_inline_in_doc: 'Attachments Preview on Document Card',
    msg_no_delete_privilege: 'No Delete Privilege',
    msg_no_create_privilege: 'No Create Privilege',
    msg_no_save_privilege: 'No Save Privilege',
    balance_report: 'Balance',
    date1: 'Date 1',
    date2: 'Date 2',
    date3: 'Date 3',
    date4: 'Date 4',
    report: 'Report',
}

export default messages